import SectionHeader from '../components/sections/header';
import SectionBannerBlock from '../components/sections/banner-block';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionPeopleListing from '../components/sections/people-listing';
import SectionPlain from '../components/sections/plain';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { endpoints } from '../config/endpoints';
import { IPage } from '../models/page.model';
import { IPeople } from '../models/people.model';
import { useSettings } from '../hooks/use-settings';
import { useList } from '../hooks/use-list';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IPeopleProps {
    readonly data: {
        page: IPage;
    };
}

const DEFAULT_PRODUCTS_PER_PAGE = 24;

const People: React.FC<IPeopleProps> = ({ data }) => {
    const { page } = data;
    const [perPageSetting, perRowSetting] = useSettings([
        'shop.product.itemsPerPage',
        'shop.product.itemsPerRow',
    ]);

    const { items, status, isInitialLoading, paginationPaths, pagination, values } =
        useList<IPeople>({
            endpoint: endpoints.people,
            perPage: perPageSetting?.value
                ? Number(perPageSetting.value)
                : DEFAULT_PRODUCTS_PER_PAGE,
        });

    const sectionsWithData = page.sections.map((section) => {
        if (section.type === 'people-listing') {
            return {
                ...section,
                items: {
                    people: items,
                },
                settings: {
                    ...section.settings,
                    values,
                    status,
                    isInitialLoading,
                    paginationPaths,
                    pagination,
                    perRow: perRowSetting?.value,
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            {sectionsWithData.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            pathname
            type
            sections {
                ...sectionFields
            }
        }
    }
`;

export default People;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'banner-block': SectionBannerBlock,
    'breadcrumbs': SectionBreadcrumbs,
    'people-listing': SectionPeopleListing,
    'plain': SectionPlain,
    'footer': SectionFooter,
};