import React from 'react';

import { ISection } from '../../models/section.model';
import IBanner from '../../models/banner.model';
import Section from '../hoc/section';
import Banner from '../molecules/banner';

export interface IBannerBlock extends ISection {
    items: {
        banner: IBanner;
    };
}

interface IBannerBlockProps {
    className?: string;
    section: IBannerBlock;
    TitleTag?: React.ElementType;
}

export default function BannerBlock({ section, TitleTag, className }: IBannerBlockProps) {
    const {
        sectionId,
        items: { banner },
        css,
        style,
        sequence,
    } = section;

    return (
        <Section sectionId={sectionId} className={className} css={css} style={style}>
            <Banner banner={banner} TitleTag={TitleTag} loading={sequence < 3 ? 'eager' : 'lazy'} />
        </Section>
    );
}
