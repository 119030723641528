import React from 'react';

import { grid, listBox, listingGrid, listItem, loader } from './people-listing.module.scss';

import { ISection } from '../../models/section.model';
import { IPeople } from '../../models/people.model';
import { TStatus } from '../../models/status.model';
import { IPagination } from '../../models/pagination.model';

import Pagination, { IPaginationProps } from '../molecules/pagination';
import Loader from '../atoms/loader';
import Section from '../hoc/section';
import PeopleCard from '../molecules/people-card';

interface IPeopleListingSection extends ISection {
    items: {
        people: IPeople[];
    };
    settings: {
        status: TStatus;
        isInitialLoading: boolean;
        paginationPaths: string[];
        pagination?: IPagination;
        perRow: number;
    };
}

interface IPeopleListingProps {
    className?: string;
    section: IPeopleListingSection;
    TitleTag?: React.ElementType;
    paginationProps?: IPaginationProps;
}

const PeopleListing: React.FC<IPeopleListingProps> = ({ className = '', section }) => {
    const {
        sectionId,
        css,
        style,
        items: { people },
        settings: { status, isInitialLoading, paginationPaths },
    } = section;

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={listBox}>
                {isInitialLoading && status === 'error' && (
                    <div>
                        <p>blad</p>
                    </div>
                )}
                {isInitialLoading && status !== 'error' && <Loader className={loader} />}
                {!isInitialLoading && (
                    <>
                        {(status === 'success' || status === 'loading') && people.length > 0 && (
                            <ul className={listingGrid}>
                                {people.map((person) => {
                                    return (
                                        <li
                                            className={listItem}
                                            key={`post-card-item-${person.personId}`}
                                        >
                                            <PeopleCard person={person} />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <Pagination paginationPaths={paginationPaths} />
                    </>
                )}
            </div>
        </Section>
    );
};

export default PeopleListing;
